import {environment as rivalEnvironment} from "./environment.rival";

export const environment = {
  ...rivalEnvironment,

  rivalName: "slotsjackpot",
  casinoId: 47,
  GA_TRACKING:'G-0NE9Q1QJ5W',

  rivalChatGroupName: "Slots Jackpot",
  prettyName: "Slots Jackpot",
  contactEmail: "support@slotsjackpotcasino.com",
  docsEmail: "documents@slotsjackpotcasino.email",

  //SEO
  metaDescription: "Win Big at Slots Jackpot Casino! Play over 500 top-rated games, including progressive slots and live dealer action. Enjoy secure online gaming, fast payouts, and exclusive casino bonuses with free spins. Join now to experience the thrill of real money wins and hit the jackpot!",
  logoName: "slots-jackpot-online-casino-logo.png",
  logoAltText: "Slots Jackpot Casino online logo with bold red and black text featuring a jackpot lever popular for slot games progressive jackpots and casino bonuses."
};


